<template>
    <div class="WA Wrapper-section" id="WelcomeAnimationPage">
        <div class="WA-cta" id="WA-cta" ref="cta">
            <router-link :to="{name: 'WelcomeToJFI'}" @click.native="activeLink" aria-label="Read More">
                <svg class="WA-cta-svg" ref="ctaSVG">
                    <use xlink:href="#symbol-ArrowDown"></use>
                </svg>
                <span>Read more</span>
            </router-link>
        </div>
        <div class="WA-container"><!--@click="this.WAHandleClick" -->
            <div class="WA-salers" ref="salers">
                <div class="WA-salers-saler WA-salers-saler_1" ref="saler1">
                    <p class="WA-texts-text WA-texts-text_1" ref="text1">Hi I’m sales<br />& he’s marketing</p>
                    <img :src="`img/welcomeAnimation/1-Sales.jpg`" alt="Sales" />
                </div>
                <div class="WA-salers-saler WA-salers-saler_2" ref="saler2">
                    <p class="WA-texts-text WA-texts-text_2" ref="text2"></p>
                    <img :src="`img/welcomeAnimation/2-Marketing.jpg`" alt="Marketing" />
                </div>
                <div class="WA-salers-saler WA-salers-saler_3" ref="saler3">
                    <p class="WA-texts-text WA-texts-text_3" ref="text3">I’m<br />software</p>
                    <img :src="`img/welcomeAnimation/3-Software.jpg`" alt="Software" />
                </div>
                <div class="WA-salers-saler WA-salers-saler_4" ref="saler4">
                    <p class="WA-texts-text WA-texts-text_4" ref="text4">& I’m<br />design</p>
                    <img :src="`img/welcomeAnimation/4-Design.jpg`" alt="Design" />
                </div>
            </div>
            <div class="WA-title" ref="title">
                <div class="WA-title-vanish" ref="titleVanish">
                    <h1>Sales<span class="WA-title-letters WA-title-letters_spacer"> & </span>Ma<span class="WA-title-letters">r</span>k<span class="WA-title-letters">et</span>ing
                    </h1>
                </div>
                <div class="WA-title-before" ref="titleBefore">
                    <h1>Sales & Marketing</h1>
                </div>
                <div class="WA-conclusion WA-conclusion_before" ref="conclusionBefore">
                    <h2 class="WA-conclusion-text WA-conclusion-text_before" ref="conclusionTextBefore">Same old voices getting the same old results?</h2>
                </div>
                <div class="WA-title-after" ref="titleAfter"><h1>SalesMaking</h1></div>
                <div class="WA-conclusion WA-conclusion_after" ref="conclusion">
                    <h2 class="WA-conclusion-text WA-conclusion-text_after" ref="conclusionText">The approach your customers would design for you.</h2>
                </div>
            </div>
            <div class="WA-alternative" ref="alternative">
                <p class="WA-alternative-text WA-alternative-items" ref="alternativeText">You need<br />to work<br />with</p>
                <svg class="WA-alternativeLogo WA-alternative-items" ref="alternativeLogo">
                    <use xlink:href="#symbol-JFILogo-text_fox"></use>
                </svg>
            </div>
            <div class="WA-fox" ref="fox">
                <img :src="`img/welcomeAnimation/5-JFIFox.png`" alt="JFIFox" />
            </div>
            <div class="WA-circle" ref="circle"></div>
            <div class="WA-pattern" ref="pattern">
                <svg class="WA-pattern-svg">
                    <use xlink:href="#symbol-patternShape"></use>
                </svg>
            </div>
        </div>
        <div class="Trigger TriggerBottom" id="WATriggerBottom"></div>
    </div>
</template>

<script>
    import { TimelineLite, Power0, Power2, Elastic } from 'gsap';

    export default {
        name: 'WelcomeAnimation',
        components: {},
        data() {
            return {
                sceneTimer: null,
                timeline: null,
                timelineShape: null
            };
        },
        mounted() {
            this.$nextTick(this.prepareScene);
        },
        methods: {
            prepareScene() {
                this.sceneTimer = setInterval(this.createScene, 800);
            },
            createScene() {
                this.animation();
                clearInterval(this.sceneTimer);

                // All Sections
                const SMC = new this.$scrollmagic.Controller();
                const scene = new this.$scrollmagic.Scene({
                    triggerElement: `#WATriggerBottom`,
                    triggerHook: 'onEnter',
                    offset: 150
                }).setClassToggle(`#WA-cta`, 'hide'); // add class to reveal
                SMC.addScene(scene);
            },
            animation() {
                const { salers, saler1, saler2, saler3, saler4, text1, text2, text3, text4, fox, title, titleVanish, titleBefore, titleAfter, alternative, alternativeText, alternativeLogo, conclusion, conclusionBefore, circle, pattern, ctaSVG } = this.$refs;
                const CubeBig = '#CubeBig';
                const CubeSmallLeft = '#CubeSmallLeft';
                const CubeSmallRight = '#CubeSmallRight';
                const ArrowUpBig = '#ArrowUpBig';
                const ThreeQuarterCircle = '#ThreeQuarterCircle';
                const CrossBigRight = '#CrossBigRight';

                this.timeline = new TimelineLite({
                    paused: true
                    //onComplete: () => this.timeline.restart()
                });
                this.timelineShape = new TimelineLite({
                    paused: true
                    //onComplete: () => this.timelineShape.restart()
                });
                this.timelineShape.set([CubeBig, CubeSmallLeft, CubeSmallRight, ThreeQuarterCircle, CrossBigRight], {
                    transformStyle: 'preserve-3d',
                    transformOrigin: 'center',
                    backfaceVisibility: 'hidden',
                    rotation: 0
                });
                this.timelineShape.set([ArrowUpBig], { y: 400, opacity: 1 });

                this.timelineShape
                    .to(CubeBig, 10, { rotation: -360, ease: Power0.easeNone, repeat: -1 })
                    .to(CubeBig, 2, { opacity: 1, scale: 2, ease: Power0.easeNone, repeat: -1, yoyo: true }, 0)
                    .to(CubeSmallLeft, 5, { rotation: 360, repeat: -1, ease: Power0.easeNone }, 0)
                    .to(CubeSmallRight, 3, { rotation: 360, repeat: -1, ease: Power0.easeNone }, 0)
                    .to(ArrowUpBig, 2, { y: -50, opacity: 0, repeat: -1, ease: Power2.easeIn, delay: 1 }, 0)
                    .to(ThreeQuarterCircle, 1, { rotation: 360, repeat: -1, ease: Power0.easeNone }, 0)
                    .to(CrossBigRight, .5, { rotation: 360, repeat: -1, ease: Power0.easeNone }, 0)
                ;
                this.timelineShape.play();

                this.timeline.set(salers, { left: '100vw', xPercent: 100 });
                this.timeline.set([saler1, saler2, saler3, saler4], { opacity: 0 });
                this.timeline.set([text1, text2, text3, text4], { opacity: 0 });

                const titleLeft = (window.innerWidth <= 500) ? 25 : 50;
                this.timeline.set([title], { left: `${titleLeft}vw`, opacity: 1 });
                this.timeline.set([titleVanish, titleBefore, titleAfter, conclusion, conclusionBefore], {
                    opacity: 0,
                    transformStyle: 'preserve-3d',
                    transformOrigin: 'center',
                    backfaceVisibility: 'hidden'
                });
                this.timeline.set([alternative], { left: '100vw', opacity: 0 });
                this.timeline.set([alternativeText, alternativeLogo], { opacity: 1 });
                this.timeline.set([circle, pattern], { opacity: 0 });
                this.timeline.set(fox, { left: '100vw', xPercent: 0, bottom: 0, opacity: 0 });

                this.timeline.add('Start');

                this.timeline.add('OldWorldStart');
                let salersStartWidth = '70vw';
                let salersEndWidth = '60vw';
                if (window.innerWidth <= 500) {
                    salersStartWidth = '95vw';
                    salersEndWidth = '90vw';
                }
                // Old salers arrives from the right
                this.timeline.set(salers, { opacity: 1 });
                this.timeline.to(salers, 1, { left: '50vw', width: salersStartWidth, xPercent: -50 }, 'OldWorldStart');
                this.timeline.pause();

                this.timeline.staggerTo('.WA-salers-saler', .5, { opacity: 1, delay: 1 }, .2, '-=2');
                // They present themself
                this.timeline.staggerTo('.WA-texts-text', .5, { opacity: 1, delay: .9, marginLeft: '-2vw' }, .5, '-=1');
                this.timeline.to(salers, 1, { width: salersEndWidth });
                // Title arrive
                this.timeline.to(titleVanish, 1, { opacity: 1 }, '-=.5');
                this.timeline.to(conclusionBefore, 1, { opacity: 1 }, '-=.5');

                this.timeline.add('OldWorldEnd');

                // Oll the picture is gently sliding away to the left
                this.timeline.add('OldWorldStayStart');
                this.timeline.to([title], 3, { left: `${titleLeft - 5}vw`, ease: Power0.linear }, '-=.5');
                this.timeline.to([salers], 3, { left: '45vw', ease: Power0.linear }, '-=3');
                this.timeline.to(conclusionBefore, 1, { opacity: 0 }, '-=.5');

                this.timeline.add('OldWorldStayEnd');

                // Old salers fade away to the left
                this.timeline.add('AlternativeStart');
                this.timeline.to(title, 2, { left: 0, opacity: 0, xPercent: -100, delay: 3, ease: Power2.easeOut }, '-=3.5');
                this.timeline.to(salers, 2.5, { left: 0, xPercent: -100, ease: Power2.easeIn }, '-=1.8');
                this.timeline.staggerTo('.WA-salers-saler', .3, { opacity: 0, left: '-=10vw', ease: Power2.easeIn }, .1, '-=2.7');
                this.timeline.set([titleVanish], { opacity: 0 });

                // The alternative
                this.timeline.to(alternative, 1, { left: '3.5vw', opacity: 1, xPercent: 0, ease: Power2.easeOut, scale: 1 }, '-=2.2');
                this.timeline.to(fox, 1, { left: '10vw', opacity: 1, ease: Power2.easeOut }, '-=2.1');
                this.timeline.to(circle, .5, { opacity: 1 }, '-=1.8');
                this.timeline.to(pattern, 1, { left: '18vw', opacity: 1 }, '-=1.6');
                this.timeline.add('AlternativeEnd');

                // Our solution
                this.timeline.set(title, { left: `${titleLeft}vw`, opacity: 1, xPercent: 0 });
                this.timeline.to(titleVanish, 1, { opacity: 1 });
                this.timeline.staggerTo('.WA-title-letters', .3, { opacity: 0, fontSize: 0, ease: Elastic.easeOut.config(1, 0.3) }, .5);
                this.timeline.to(title, 1, { left: `${titleLeft + 5}vw` });

                this.timeline.set([conclusion], { opacity: 0, rotationX: -90, transformOrigin: 'top center' });
                this.timeline.to(conclusion, .5, { rotationX: 0, opacity: 1, delay: .3 });

                this.timeline.to(ctaSVG, .25, { opacity: 0, repeat: 5, yoyo: true, ease: Power2.easeOut, delay: 2 });

                this.timeline.add('End');
                this.timeline.play();
            },
            WAHandleClick() {
                return this.timeline.restart();
            },
            activeLink: async function (el) {
                this.$root.$emit('activeLink', el);
            }
        }
    };
</script>
